<template>
  <sm-categories-lists
    :breadcrumbs="breadcrumbs"
    :categories-group-name="categoriesGroupName"
    :list-name="categoriesGroupName"
  />
</template>

<script>
// components
import SmCategoriesLists from '@/components/views/SmCategoriesLists.vue';

export default {
  name: 'MonitoringMain',

  components: {
    SmCategoriesLists,
  },

  data() {
    return {
      breadcrumbs: [{ text: 'Мониторинг' }],
      categoriesGroupName: 'monitoring',
      categoriesGroup: [
        {
          title: 'Отчеты',
          list: [
            {
              name: 'Отчет по заявкам ИС',
              route: { name: 'ReportISRequests' },
              controllerName: 'ReportISRequests',
            },
            {
              name: 'Отчет по заявкам АДС во всех базах',
              route: { name: 'AdsRequestsPerformingReport' },
              controllerName: 'AdsRequestsPerformingReport',
            },
            {
              name: 'Отчет о заявках партнеров',
              route: { name: 'PartnersRequestsReport' },
              controllerName: 'PartnersRequestsReport',
            },
            {
              name: 'Сводный отчет по очереди заданий',
              route: { name: 'RequestQueryReport' },
              controllerName: 'RequestQueryReport',
            },
            {
              name: 'Отчет по текущим заявкам сотрудника в работе',
              route: { name: 'RequestsReport' },
              controllerName: 'RequestsReport',
            },
            {
              name: 'Отчет по принятым звонкам сотрудников',
              route: { name: 'EmployeeCallReport' },
              controllerName: 'EmployeeCallReport',
            },
          ],
        },
        {
          title: 'Платежи и мобильное приложение',
          list: [
            {
              name: 'Список мобильных пользователей по базам',
              route: { name: 'MobileUsersByDatabasesReport' },
              controllerName: 'MobileUsersByDatabasesReport',
            },
            {
              name: 'Отчет по мобильным пользователям сводный',
              route: { name: 'MobileUsersByDatabasesFullReport' },
              controllerName: 'MobileUsersByDatabasesFullReport',
            },
            {
              name: 'Отчет по реестрам оплат ЛК и МП',
              route: { name: 'PaymentsRegistryStatsReport' },
              controllerName: 'PaymentsRegistryStatsReport',
            },
            {
              name: 'Отчет по реестрам оплат ЛК и МП помесячно',
              route: { name: 'PaymentsRegistryReport' },
              controllerName: 'PaymentsRegistryReport',
            },
            {
              name: 'Отчет по количеству активных пользователей в МП и ЛК',
              route: { name: 'MobileUsersActivityReport' },
              controllerName: 'MobileUsersActivityReport',
            },
          ],
        },
        {
          title: 'Страхование',
          list: [
            {
              name: 'Базы данных со страхованием',
              route: { name: 'DatabasesWithInsurances' },
              controllerName: 'DatabasesWithInsurances',
            },
            {
              name: 'Отчет по страхованию',
              route: { name: 'InsuranceReport' },
              controllerName: 'InsuranceReport',
            },
          ],
        },
        {
          title: 'Показания приборов учета',
          list: [
            {
              name: 'Cбор данных показаний - фотографии',
              route: { name: 'MeterValuesReaderStatistic' },
              controllerName: 'MeterValuesReaderStatistic',
            },
          ],
        },
      ],
    };
  },
};
</script>
